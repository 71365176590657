<template>
  <v-row justify="center">
    <v-dialog :value="true" persistent max-width="540px" transition="dialog-transition" @input="$emit('close-dialog')">
      <v-card>
        <v-form @submit.prevent="create">
          <v-card-title>
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title class="text-h5 font-weight-bold" v-text="$t('project.createProjectDialog.newProject')" />
              </v-list-item-content>
              <v-spacer />
              <v-list-item-action>
                <v-btn icon color="primary" @click="$emit('close-dialog')">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-title>
          <v-card-text>
            <v-select
              v-model="form.type"
              :error-messages="getFieldErrors('type')"
              :label="$t('common.type')"
              :items="typeItems"
              outlined
              dense
              class="mb-1"
              @blur="$v.form.type.$touch()"
              @change="form = {...form, ...defaultFeatures}"
            />
            <v-select
              v-if="form.type === 'competition'"
              v-model="form.presetType"
              :error-messages="getFieldErrors('presetType')"
              :label="$t('project.createProjectDialog.presetType.label')"
              :items="presetTypeItems"
              outlined
              dense
              class="mb-1"
              @blur="$v.form.presetType.$touch()"
            />
            <v-select
              v-if="form.type === 'competition'"
              v-model="form.features.calendarType"
              :error-messages="getFieldErrors('features.calendarType')"
              :label="$t('project.createProjectDialog.calendarType.label')"
              :items="calendarTypeItems"
              outlined
              dense
              class="mb-1"
              @blur="$v.form.features.calendarType.$touch()"
            />
            <v-text-field
              v-model="form.name"
              :error-messages="getFieldErrors('name')"
              :label="$t('common.name')"
              outlined
              dense
              class="mb-1"
              @blur="$v.form.name.$touch()"
            />
            <v-autocomplete
              v-model="form.timezone"
              :error-messages="getFieldErrors('timezone')"
              :label="$t('common.timeZone')"
              :items="timezoneItems"
              outlined
              dense
              class="mb-1"
              @blur="$v.form.timezone.$touch()"
            />
            <v-layout v-for="(item, i) in allCheckboxes" :key="i" row wrap>
              <v-checkbox v-if="filteredCheckboxes[item.name]" v-model="form[item.model][item.name]" dense class="px-3">
                <template #label>
                  <div>
                    {{ item.text }}
                    <global-tooltip
                      v-if="item.tooltip"
                      :text="item.tooltip"
                    />
                  </div>
                </template>
              </v-checkbox>
            </v-layout>
          </v-card-text>
          <v-card-actions class="px-6 pb-5 pt-0 d-flex justify-center">
            <v-btn color="primary" depressed type="submit" v-text="$t('project.createProjectDialog.createProject')" />
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'
import timezonesEnum from '@/enums/timezonesEnum'

export default {
  name: 'CreateProjectDialog',
  components: {
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
  },
  mixins: [formUtils],
  props: {
    organizationId: { type: String, required: true },
  },
  data() {
    return {
      form: {
        type: null,
        presetType: null,
        name: null,
        timezone: '',
        features: {
          hasRegions: false,
          hasMatchEvents: false,
          clubPrice: false,
          terms: false,
          form: false,
          hasMatchLocations: false,
          price: false,
          calendarType: null,
        },
        rrobinStage: {
          isDouble: false,
        },
        subscriberApproval: {
          required: false,
        },
        cardDescription: null,
      },
      typeItems: [
        { text: this.$t('project.type.short-term'), value: 'short-term' },
        { text: this.$t('project.type.long-term'), value: 'long-term' },
        { text: this.$t('project.type.competition'), value: 'competition' },
        { text: this.$t('project.type.academy'), value: 'academy' },
      ],
      presetTypeItems: [
        { text: this.$t('project.createProjectDialog.presetType.mirrorAlphabet'), value: 'mirrorAlphabet' },
        { text: this.$t('project.createProjectDialog.presetType.nba'), value: 'nba' },
      ],
      calendarTypeItems: [
        { text: this.$t('project.createProjectDialog.calendarType.day'), value: 'day' },
        { text: this.$t('project.createProjectDialog.calendarType.month'), value: 'month' },
      ],
      timezoneItems: timezonesEnum.map(v => ({ text: v, value: v })),
    }
  },
  computed: {
    isLongTerm: ({ form }) => form.type === 'long-term',
    isShortTerm: ({ form }) => form.type === 'short-term',
    isCompetition: ({ form }) => form.type === 'competition',
    isAcademy: ({ form }) => form.type === 'academy',
    allCheckboxes() {
      return [
        {
          model: 'features',
          name: 'hasRegions',
          text: this.$t('project.createProjectDialog.checkbox.hasRegions'),
          tooltip: this.$t('project.createProjectDialog.tooltip.hasRegions'),
        },
        {
          model: 'features',
          name: 'hasMatchEvents',
          text: this.$t('project.createProjectDialog.checkbox.hasMatchEvents'),
          tooltip: this.$t('project.createProjectDialog.tooltip.hasMatchEvents'),
        },
        {
          model: 'rrobinStage',
          name: 'isDouble',
          text: this.$t('project.createProjectDialog.checkbox.isDouble'),
        },
        {
          model: 'features',
          name: 'price',
          text: this.$t('project.createProjectDialog.checkbox.price'),
        },
        {
          model: 'features',
          name: 'clubPrice',
          text: this.$t('project.createProjectDialog.checkbox.priceCompetition'),
          tooltip: this.$t('project.createProjectDialog.tooltip.priceCompetition'),
        },
        {
          model: 'subscriberApproval',
          name: 'required',
          text: this.$t('project.createProjectDialog.checkbox.subscriberApproval'),
          tooltip: this.$t('project.createProjectDialog.tooltip.subscriberApproval'),
        },
        {
          model: 'features',
          name: 'terms',
          text: this.isCompetition ? this.$t('project.createProjectDialog.checkbox.termsCompetition') : this.$t('project.createProjectDialog.checkbox.terms') ,
          tooltip: this.$t('project.createProjectDialog.tooltip.terms'),
        },
        {
          model: 'features',
          name: 'form',
          text: this.isCompetition ? this.$t('project.createProjectDialog.checkbox.formCompetition') : this.$t('project.createProjectDialog.checkbox.form'),
          tooltip: this.$t('project.createProjectDialog.tooltip.form'),
        },
        {
          model: 'features',
          name: 'hasMatchLocations',
          text: this.$t('project.createProjectDialog.checkbox.hasMatchLocations'),
          tooltip: this.$t('project.createProjectDialog.tooltip.hasMatchLocations'),
        },
      ]
    },
    filteredCheckboxes: ({ form, isCompetition, isAcademy }) => ({
      hasRegions: isCompetition,
      hasMatchEvents: isCompetition,
      isDouble: isCompetition,
      price: form.type && !isAcademy && !isCompetition,
      clubPrice: isCompetition,
      required: form.type && !isAcademy && !isCompetition,
      terms: form.type && !isAcademy,
      form: form.type && !isAcademy,
      hasMatchLocations: isCompetition,
    }),
    defaultFeatures({ isCompetition, isAcademy }) {
      return {
        features: {
          terms: isAcademy,
          hasRegions: isCompetition,
          hasMatchEvents: false,
          form: isAcademy,
          // price: isAcademy, // TODO: uncomment
          price: false,
          clubPrice: false,
          hasMatchLocations: false,
          removeSubscribersLabel: true,
          enabledChat: isAcademy,
          parental: isAcademy || isCompetition,
        },
        subscriberApproval: {
          required: isAcademy,
        },
      }
    },
  },
  validations() {
    return {
      form: {
        type: { required },
        presetType: { required: requiredIf(() => this.form.type === 'competition') },
        features: {
          calendarType: { required: requiredIf(() => this.form.type === 'competition') },
        },
        name: { required },
        timezone: { required },
      },
    }
  },
  methods: {
    parseForm() {
      return {
        ...this.form,
        subtype: this.form.type === 'competition' ? 'rrobin+elimination' : null,
        ...(
          this.form.subscriberApproval.required && ({
            features: {
              ...this.form.features,
            },
            subscriberApproval: {
              ...this.form.subscriberApproval,
              active: true,
            },
          })
        ),

        ...(this.isCompetition && {
          features: {
            ...this.form.features,
          },
        }),
      }
    },
    async create() {
      const { organizationId, parseForm } = this
      if (!this.isFormValid()) return
      await this.runAsync(async () => {
        const id = await this.$store.dispatch('project/create', { organizationId, data: parseForm() })
        this.$emit('close-dialog')
        this.$emit('next', id)
      })
    },
  },
}
</script>
